import React from "react";
import Grid from "./grid";

export default function BlockSteps() {
  return (
    <div className="block-steps">
      <Grid
        container
        maxWidth="lg"
        columnSpacing={{
          xs: 2,
        }}
        rowSpacing={{
          xs: 8,
        }}
      >
        <Grid
          xs={12}
          sx={{
            my: {
              md: 14,
              sm: 14,
            },
          }}
        >
          <div className="block-steps-content">
            <h2 className="block-title">Vendre ma voiture en 4 étapes </h2>
            <div className="cards-wrapper">
              <div className="card-step">
                <div className="card-wrapper">
                  <div className="card-head">
                    <p>1. Prise de rendez-vous</p>
                  </div>
                  <div className="card-body">
                    <p>
                      Suite à votre demande de contact, nous prenons rendez-vous
                      avec un Chasseur d’Autos. Vous avez juste à préparer les
                      documents clés nécessaires (Entretien / Contrôle Technique
                      / Carte Grise)
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-step card-step-dk">
                <div className="card-wrapper">
                  <div className="card-head">
                    <p>3. Mise en ligne</p>
                  </div>
                  <div className="card-body">
                    <p>
                      Nous diffusons l’annonce de votre véhicule sur les
                      principaux sites à forte audience automobile. Nous gérons
                      pour vous les prises de contacts et organisons les visites
                      avec les potentiels acheteurs.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card-step card-step-dk">
                <div className="card-wrapper">
                  <div className="card-head">
                    <p>2. Visite et Prise de photos</p>
                  </div>
                  <div className="card-body">
                    <p>
                      La visite est effectuée sur le lieu de rencontre de votre
                      choix afin de faire un point sur l’état général de votre
                      véhicule et de faire des photos valorisantes de celui ci.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card-step card-step-mb">
                <div className="card-wrapper">
                  <div className="card-head">
                    <p>2. Visite et Prise de photos</p>
                  </div>
                  <div className="card-body">
                    <p>
                      La visite est effectuée sur le lieu de rencontre de votre
                      choix afin de faire un point sur l’état général de votre
                      véhicule et de faire des photos valorisantes de celui ci.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-step card-step-dk">
                <div className="card-wrapper">
                  <div className="card-head">
                    <p>4. Vente de votre véhicule</p>
                  </div>
                  <div className="card-body">
                    <p>
                      Nous sécurisons le transfert des fonds avec notre
                      partenaire CashSentinel et assurons une garantie de 6 mois
                      sur le véhicule !
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-step card-step-mb">
                <div className="card-wrapper">
                  <div className="card-head">
                    <p>3. Mise en ligne</p>
                  </div>
                  <div className="card-body">
                    <p>
                      Nous diffusons l’annonce de votre véhicule sur les
                      principaux sites à forte audience automobile. Nous gérons
                      pour vous les prises de contacts et organisons les visites
                      avec les potentiels acheteurs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-step card-step-mb">
                <div className="card-wrapper">
                  <div className="card-head">
                    <p>4. Vente de votre véhicule</p>
                  </div>
                  <div className="card-body">
                    <p>
                      Nous sécurisons le transfert des fonds avec notre
                      partenaire CashSentinel et assurons une garantie de 6 mois
                      sur le véhicule !
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
